<template>
  <div>
    <div v-if="isModalVisible">
      <mds-modal
        v-model="isModalVisible"
        width="600px"
      >
        <cron-expression-form
          ref="cronExpressionForm"
          :selected-parser="selectedParser"
          :selected-cron-expression="selectedCronExpression"
          :parsers-cron-expressions="cronExpressions"
          :is-cron-expression-unique="isCronExpressionUnique"
          @updateCronExpressions="updateCronExpressions"
          @updateModalIsVisible="updateModalIsVisible"
        />
      </mds-modal>
    </div>
    <mds-modal
      v-model="isDeleteModalVisible"
      aria-labelledby="title-id"
      action-required
    >
      <mds-section
        border-position="bottom"
        bold
      >
        <template #mds-section-title>
          <span id="title-id">Delete Scheduler Expression {{ selectedCronExpression?.name }}</span>
        </template>
        <template #mds-section-actions>
          <mds-button-container right-aligned>
            <mds-button
              variation="secondary"
              @click="isDeleteModalVisible = !isDeleteModalVisible"
            >
              Cancel
            </mds-button>
            <mds-button
              variation="primary"
              :loading="isLoading"
              :disabled="isLoading"
              @click="deleteSelectedCronExpression"
            >
              Delete
            </mds-button>
          </mds-button-container>
        </template>
        {{ $t(`cronExpressions.deleteCronExpression`) }}
      </mds-section>
    </mds-modal>
    <div>
      <mds-button-container right-aligned>
        <mds-button
          id="cron-expressions"
          variation="flat"
          icon="info-circle"
          type="button"
          @click="promptToggle=!promptToggle"
        />
        <mds-tooltip
          v-model="promptToggle"
          triggered-by="cron-expressions"
          :position="['left-center']"
          class="cron-form__tooltip"
        >
          {{ this.$t(`cronExpressions.maxLength`) }}
        </mds-tooltip>
        <mds-button
          variation="flat"
          icon="plus"
          text="Add Expression"
          type="button"
          :disabled="cronExpressions.length >= 10"
          @click.stop="toggleModal"
        />
      </mds-button-container>
      <mds-table row-hover>
        <mds-thead>
          <mds-th
            v-for="(header, index) in $options.headers"
            :key="index"
            :style="header.style"
            v-text="header.text"
          />
        </mds-thead>
        <mds-tbody>
          <mds-tr
            v-for="cronExpre in cronExpressions"
            :key="cronExpre.id"
          >
            <mds-td
              v-for="(header, index) in $options.headers"
              :key="index"
              :right-aligned="header.align === 'right'"
              :style="header.style"
            >
              <template v-if="header.fieldName === 'isActive' || header.fieldName === 'isEnabledRetro'">
                <status-tag :status="cronExpre[header.fieldName] ? 'ACTIVE' : 'INACTIVE'" />
              </template>
              <template v-else-if="header.fieldName === 'actions'">
                <mds-button
                  variation="icon-only"
                  icon="remove"
                  type="button"
                  text="Remove"
                  @click="toggleDeleteModal(cronExpre)"
                />
                <mds-button
                  variation="icon-only"
                  icon="create"
                  text="Edit"
                  @click.stop="toggleModal(event, cronExpre)"
                />
              </template>
              <template v-else>
                {{ cronExpre[header.fieldName] }}
              </template>
            </mds-td>
          </mds-tr>
        </mds-tbody>
      </mds-table>
    </div>
  </div>
</template>

<script>
import { MdsButton, MdsButtonContainer } from '@mds/button';
import { MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd } from '@mds/data-table';
import MdsModal from '@mds/modal';
import MdsSection from '@mds/section';
import MdsTooltip from '@mds/tooltip';

import { cronExpressionFactory } from '@/utils/expressions.util';
import CronExpressionForm from '../cron/CronExpressionForm';
import StatusTag from '@/components/ui/StatusTag';

import { EXPRESSIONS_HEADERS } from '@/constants/cron-expressions.constant';
import { MODULE, COMMON } from '@/constants/store.constant';
import { deleteJobScheduler } from '@/services/api/job-schedulers.service.js';

export default {
  name: 'ParsersTable',
  components: {
    MdsButtonContainer,
    MdsButton,
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsModal,
    MdsSection,
    MdsTooltip,
    CronExpressionForm,
    StatusTag,
  },
  props: {
    jobs: {
      type: Array,
      default: () => [],
    },
    selectedParser: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      cronExpressions: [],
      isModalVisible: false,
      selectedCronExpression: {},
      event: null,
      isDeleteModalVisible: false,
      promptToggle: false,
      isLoading: false,
    };
  },
  watch: {
    jobs: {
      handler () {
        this.cronExpressions = this.jobs;
      },
      deep: true,
    },
  },
  mounted () {
    this.cronExpressions = this.jobs;
  },
  methods: {
    isCronExpressionUnique (cronExpression, editingId = null) {
      return !this.cronExpressions.some((cronExpr) => {
        return cronExpr.cron === cronExpression && (editingId === null || cronExpr.id !== editingId);
      });
    },
    addCronExpression () {
      const hasExpressions = this.cronExpressions.every(({ cronExpression }) => cronExpression);
      if (!hasExpressions) {
        return;
      };
      const newCronExpression = cronExpressionFactory({ cronExpression: this.cronExpressionString, cronExpressionReadable: this.readableExpression });

      this.cronExpressions = [...this.cronExpressions, newCronExpression];
      this.isModalVisible = false;
    },
    removeCronExpression (cronExpressionId) {
      const filteredCronExpressions = this.cronExpressions.filter(({ id }) => id !== cronExpressionId);
      this.updateCronExpressions(filteredCronExpressions);
    },
    toggleModal (event, cronExpre) {
      this.isModalVisible = !this.isModalVisible;
      this.selectedCronExpression = cronExpre;
    },
    toggleDeleteModal (cronExpre) {
      this.isDeleteModalVisible = !this.isDeleteModalVisible;
      this.selectedCronExpression = cronExpre;
    },
    updateCronExpressions (cronExpressions) {
      this.cronExpressions = [...cronExpressions];
      this.$store.commit(`${MODULE.PARSERS_JOB_SCHEDULERS}/${COMMON.UPDATE_STATE}`, { jobs: this.cronExpressions, parserId: this.selectedParser?.id });
    },
    updateModalIsVisible (isModalVisible) {
      this.isModalVisible = isModalVisible;
    },
    async deleteSelectedCronExpression () {
      this.isLoading = true;
      await deleteJobScheduler(this.selectedCronExpression.id);
      this.isLoading = false;
      this.isDeleteModalVisible = !this.isDeleteModalVisible;
      this.removeCronExpression(this.selectedCronExpression.id);
    },
  },
  headers: EXPRESSIONS_HEADERS,
};
</script>

<style lang="scss">
@import '@mds/typography';

.parsers-table {
  &__title {
    @include mds-level-4-heading($bold: true);
    margin-top: 25px;
  }
}
</style>
