<template>
  <div>
    <mds-row>
      <mds-col class="cron-form__title">
        <h3> {{ this.$t(`cronExpressions.title`) }}</h3>
        <mds-button
          id="prompt-trigger"
          variation="flat"
          icon="info-circle"
          type="button"
          @click="promptToggle=!promptToggle"
        />
        <mds-tooltip
          v-model="promptToggle"
          triggered-by="prompt-trigger"
          :position="['right-center']"
          class="cron-form__tooltip"
        >
          {{ this.$t(`cronExpressions.awsMsg`) }}
          <a
            href="https://docs.aws.amazon.com/AmazonCloudWatch/latest/events/ScheduledEvents.html"
            target="_blank"
          >{{ this.$t(`cronExpressions.awsMsgSeeMore`) }}</a>
        </mds-tooltip>
      </mds-col>
    </mds-row>
    <mds-row>
      <mds-col>
        <mds-input
          v-model="name"
          :error="!!nameError"
          :error-text="[nameError]"
          :label="this.$t(`cronExpressions.name`)"
          :disabled="selectedCronExpression.name"
          @input="setDisplayName"
        />
        <div
          v-if="!selectedCronExpression.name"
          class="cron-form__display_name"
        >
          <span>{{ `${this.$t(`cronExpressions.displayName`)}` }}</span> {{ `${displayName}` }}
        </div>
      </mds-col>
    </mds-row>
    <mds-row>
      <mds-col
        :cols="2"
      >
        <mds-fieldset legend="Status">
          <mds-switch
            v-model="isActive"
            :label="isActive ? 'Active': 'Inactive'"
            @change="handleIsActive"
          />
        </mds-fieldset>
      </mds-col>
      <mds-col
        :cols="2"
      >
        <mds-fieldset legend="Retroactivity">
          <mds-switch
            v-model="isEnabledRetro"
            :label="isEnabledRetro ? 'Active': 'Inactive'"
            :disabled="isRetroactiveDisabled"
            @change="handleIsEnabledRetro"
          />
        </mds-fieldset>
      </mds-col>
      <mds-col
        v-if="isEnabledRetro && !isRetroactiveDisabled"
        class="cron-form__retro"
        :cols="5"
      >
        <mds-fieldset>
          <mds-input
            v-model="retroInDays"
            :error="!!retroInDaysError"
            :error-text="[retroInDaysError]"
            :label="this.$t(`cronExpressions.retroactivityDays`)"
            :disabled="isRetroactiveDisabled"
            @input="validateRetroInDays($event)"
          />
        </mds-fieldset>
        <mds-button
          id="prompt-retro-days"
          variation="flat"
          icon="info-circle"
          type="button"
          @mouseover="promptToggleRetro=true"
          @mouseleave="promptToggleRetro=false"
          @focus="promptToggleRetro=true"
          @blur="promptToggleRetro=false"
          @click="promptToggleRetro=!promptToggleRetro"
        />
        <mds-tooltip
          v-model="promptToggleRetro"
          triggered-by="prompt-retro-days"
          :position="['right-center']"
          class="cron-form__tooltip"
        >
          {{ this.$t(`cronExpressions.retroactivityDaysMsg`) }}
        </mds-tooltip>
      </mds-col>
    </mds-row>
    <mds-tabs
      :content="tabsContent"
      @mds-tabs-item-active="setActiveItem"
    />
    <mds-row>
      <mds-col class="cron-form__grid">
        <template v-if="showForm === 'minutes'">
          <mds-fieldset class="cron-form__fieldset">
            <mds-input
              v-model="cronExpression.minutes"
              :label="this.$t(`cronExpressions.minutesLabel`)"
              @input="validateMinuteCronExpression($event)"
            />
          </mds-fieldset>
        </template>
        <template v-if="showForm === 'hourly'">
          <mds-fieldset class="cron-form__fieldset">
            <mds-input
              v-model="cronExpression.hourly"
              :label="this.$t(`cronExpressions.hoursLabel`)"
              @input="validateHourCronExpression($event)"
            />
            <mds-input
              v-model="cronExpression.minutes"
              :label="this.$t(`cronExpressions.minutesLabel`)"
              @input="validateHourCronExpression($event)"
            />
          </mds-fieldset>
        </template>
        <template v-if="showForm === 'daily'">
          <mds-fieldset class="cron-form__fieldset">
            <mds-input
              v-model="cronExpression.daily"
              :label="this.$t(`cronExpressions.dailyLabel`)"
              @input="validateDailyCronExpression($event)"
            />
            <mds-input
              v-model="cronExpression.hourly"
              :label="this.$t(`cronExpressions.hoursLabel`)"
              @input="validateDailyCronExpression($event)"
            />
            <mds-input
              v-model="cronExpression.minutes"
              :label="this.$t(`cronExpressions.minutesLabel`)"
              @input="validateDailyCronExpression($event)"
            />
          </mds-fieldset>
        </template>
        <template v-if="showForm === 'weekly'">
          <mds-fieldset class="cron-form__fieldset_days">
            <mds-checkbox
              v-for="dayOfWeek in daysOfWeek"
              :key="dayOfWeek.order"
              :value="dayOfWeek.day"
              @change.native="toogleDayOfWeek(dayOfWeek.day)"
            >
              {{ dayOfWeek.day | capitalizeFirstLetter }}
            </mds-checkbox>
          </mds-fieldset>
          <mds-fieldset class="cron-form__fieldset">
            <mds-input
              v-model="cronExpression.hourly"
              :label="this.$t(`cronExpressions.hoursLabel`)"
              @input="validateWeeklyCronExpression($event)"
            />
            <mds-input
              v-model="cronExpression.minutes"
              :label="this.$t(`cronExpressions.minutesLabel`)"
              @input="validateWeeklyCronExpression($event)"
            />
          </mds-fieldset>
        </template>
        <template v-if="showForm === 'monthly'">
          <mds-fieldset class="cron-form__fieldset">
            <mds-input
              v-model="cronExpression.daily"
              :label="this.$t(`cronExpressions.dailyLabel`)"
              @input="validateMonthlyCronExpression($event)"
            />
            <mds-input
              v-model="cronExpression.monthly"
              :label="this.$t(`cronExpressions.monthlyLabel`)"
              @input="validateMonthlyCronExpression($event)"
            />
            <mds-input
              v-model="cronExpression.hourly"
              :label="this.$t(`cronExpressions.hoursLabel`)"
              @input="validateMonthlyCronExpression($event)"
            />
            <mds-input
              v-model="cronExpression.minutes"
              :label="this.$t(`cronExpressions.minutesLabel`)"
              @input="validateMonthlyCronExpression($event)"
            />
          </mds-fieldset>
        </template>
        <template v-if="showForm === 'advanced'">
          <mds-fieldset class="cron-form__fieldset">
            <mds-input
              v-model="cronExpression.advanced"
              :label="this.$t(`cronExpressions.advancedLabel`)"
              @input="validateAdvancedCronExpression($event)"
            />
          </mds-fieldset>
        </template>
      </mds-col>
    </mds-row>
    <mds-row>
      <mds-col>
        <mds-inline-message
          :variation="validateCron.isValid() ? 'success' : 'error'"
          size="medium"
        >
          {{ $t(`cronExpressions.finalExpression`) }} : {{ cronExpressionString }} || {{ $t(`cronExpressions.executableTime`) }}: {{ readableExpression }}
        </mds-inline-message>
      </mds-col>
    </mds-row>
    <mds-row>
      <mds-col>
        <mds-inline-message
          v-for="error in haveErros"
          :key="error.detail"
          variation="error"
          size="medium"
        >
          {{ `${error.title}: ${error.detail}` }}
        </mds-inline-message>
      </mds-col>
    </mds-row>
    <mds-row>
      <mds-col>
        <mds-button-container right-aligned>
          <mds-button
            variation="primary"
            icon="plus"
            :text="selectedCronExpression.cron ? $t(`cronExpressions.editExpression`) : $t(`cronExpressions.addExpression`)"
            type="button"
            :disabled="disableSave"
            :loading="isLoading"
            @click="selectedCronExpression.cron ? editCronExpression() : addCronExpression()"
          />
        </mds-button-container>
      </mds-col>
    </mds-row>
  </div>
</template>

<script>
import MdsFieldset from '@mds/fieldset';
import { MdsTabs } from '@mds/tabs';
import MdsInput from '@mds/input';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsCheckbox from '@mds/checkbox';
import MdsInlineMessage from '@mds/inline-message';
import MdsTooltip from '@mds/tooltip';
import MdsSwitch from '@mds/switch';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import cronstrue from 'cronstrue';
import cron from 'cron-validate';
import cloneDeep from 'lodash.clonedeep';

import { capitalizeFirstLetter, getEnvironmentShortName } from '@/utils/global.util';
import { cronExpressionFactory } from '@/utils/expressions.util';
import { EXPRESSIONS_HEADERS } from '@/constants/cron-expressions.constant';
import { postJobScheduler, putJobScheduler } from '@/services/api/job-schedulers.service.js';

export default {
  name: 'CronExpression',
  components: {
    MdsFieldset,
    MdsTabs,
    MdsInput,
    MdsButtonContainer,
    MdsButton,
    MdsCheckbox,
    MdsInlineMessage,
    MdsSwitch,
    MdsTooltip,
  },
  setup () {
    return { v$: useVuelidate() };
  },
  filters: {
    capitalizeFirstLetter,
  },
  props: {
    selectedCronExpression: {
      type: Object,
      default: () => ({
        name: null,
      }),
    },
    parsersCronExpressions: {
      type: Array,
      default: () => [],
    },
    selectedParser: {
      type: Object,
      default: () => ({}),
    },
    isCronExpressionUnique: {
      type: Function,
      default: () => () => true,
    },
  },
  data () {
    return {
      name: this.selectedCronExpression.name ?? '',
      displayName: '',
      isActive: this.selectedCronExpression.isActive ?? false,
      isEnabledRetro: this.selectedCronExpression.isEnabledRetro ?? false,
      retroInDays: this.selectedCronExpression.retroInDays ?? '',
      cronExpressionString: this.selectedCronExpression.cron ?? '* * * * ? *',
      validateCron: cron(this.selectedCronExpression.cron ?? '* * * * ? *', {
        preset: 'aws-cloud-watch',
      }),
      readableExpression: cronstrue.toString(this.selectedCronExpression.cron ?? '* * * * ? *', { dayOfWeekStartIndexZero: false, verbose: true }),
      cronExpression: {
        minutes: '0',
        hourly: '0',
        daily: '0',
        weekly: '?',
        monthly: '0',
        advanced: this.selectedCronExpression.cron ?? '* * * * ? *',
      },
      daysOfWeek: [{
        day: 'SUN',
        order: 1,
        active: false,
      },
      {
        day: 'MON',
        order: 2,
        active: false,
      },
      {
        day: 'TUE',
        order: 3,
        active: false,
      },
      {
        day: 'WED',
        order: 4,
        active: false,
      },
      {
        day: 'THU',
        order: 5,
        active: false,
      },
      {
        day: 'FRI',
        order: 6,
        active: false,
      },
      {
        day: 'SAT',
        order: 7,
        active: false,
      }],
      showForm: this.selectedCronExpression.cron ? 'advanced' : 'minutes',
      tabsContent: [
        {
          text: 'Minutes',
          id: 'minutes',
          active: !this.selectedCronExpression.cron,
        },
        {
          text: 'Hourly',
          id: 'hourly',
          active: false,
        },
        {
          text: 'Daily',
          id: 'daily',
          active: false,
        },
        {
          text: 'Weekly',
          id: 'weekly',
          active: false,
        },
        {
          text: 'Monthly',
          id: 'monthly',
          active: false,
        },
        {
          text: 'Advanced',
          id: 'advanced',
          active: !!this.selectedCronExpression.cron,
        }
      ],
      cronExpressions: cloneDeep(this.parsersCronExpressions),
      promptToggle: false,
      promptToggleRetro: false,
      isLoading: false,
      haveErros: [],
      nameError: false,
      retroInDaysError: false,
    };
  },
  computed: {
    disableSave () {
      return this.isLoading || this.nameError || this.retroInDaysError;
    },
    envName () {
      return process.env.NODE_ENV !== 'production' ? `_${getEnvironmentShortName(process.env.NODE_ENV || 'development')}` : '';
    },
    isRetroactiveDisabled () {
      return !this.isActive;
    },
  },
  validations () {
    return {
      name: { required },
    };
  },
  watch: {
    parsersCronExpressions: {
      deep: true,
      immediate: true,
      handler (newVal) {
        this.cronExpressions = cloneDeep(newVal);
      },
    },
  },
  created () {
    this.setDisplayName();
  },
  methods: {
    getReadableExpression (expression) {
      return cronstrue.toString(expression, { dayOfWeekStartIndexZero: false, verbose: true });
    },
    validateCronExpression (expression) {
      return cron(expression, {
        preset: 'aws-cloud-watch',
      });
    },
    handleIsEnabledRetro (event) {
      if (!event) {
        this.clearRetroError();
      }
      this.retroInDays = '';
    },
    handleIsActive (event) {
      if (!event) {
        this.clearRetroError();
      }
      this.isEnabledRetro = false;
      this.retroInDays = '';
    },
    clearRetroError () {
      this.haveErros = this.haveErros.filter((error) =>
        error.title !== this.$t('cronExpressions.retroactivityInvalidNumberTitle') &&
        error.title !== this.$t('cronExpressions.retroactivityDaysErrorTitle')
      );
      this.retroInDaysError = false;
    },
    validateRetroInDays (event) {
      if (!this.isEnabledRetro) {
        return true;
      }

      this.clearRetroError();
      const days = parseInt(event, 10);

      if (isNaN(days)) {
        this.haveErros.push({
          title: this.$t('cronExpressions.retroactivityInvalidNumberTitle'),
          detail: this.$t('cronExpressions.retroactivityInvalidNumberMsg'),
        });
        this.retroInDaysError = this.$t('cronExpressions.retroactivityInvalidNumberMsg');
        return false;
      }

      if (days < 1 || days > 30) {
        this.haveErros.push({
          title: this.$t('cronExpressions.retroactivityDaysErrorTitle'),
          detail: this.$t('cronExpressions.retroactivityDaysErrorMsg'),
        });
        this.retroInDaysError = this.$t('cronExpressions.retroactivityDaysErrorMsg');
        return false;
      }

      return true;
    },
    validateAdvancedCronExpression (event) {
      this.haveErros = [];
      this.cronExpressionString = event;
      this.validateCron = this.validateCronExpression(event);
      this.readableExpression = this.getReadableExpression(event);
    },
    validateMinuteCronExpression () {
      this.haveErros = [];
      this.cronExpressionString = `${this.cronExpression.minutes} * * * ? *`;
      this.validateCron = this.validateCronExpression(this.cronExpressionString);
      this.readableExpression = this.getReadableExpression(this.cronExpressionString);
    },
    validateHourCronExpression () {
      this.haveErros = [];
      this.cronExpressionString = `${this.cronExpression.minutes ? this.cronExpression.minutes : '*'} ${this.cronExpression.hourly} * * ? *`;
      this.validateCron = this.validateCronExpression(this.cronExpressionString);
      this.readableExpression = this.getReadableExpression(this.cronExpressionString);
    },
    validateDailyCronExpression () {
      this.haveErros = [];
      this.cronExpressionString = `${this.cronExpression.minutes ? this.cronExpression.minutes : '*'} ${this.cronExpression.hourly ? this.cronExpression.hourly : '*'} ${this.cronExpression.daily} * ? *`;
      this.validateCron = this.validateCronExpression(this.cronExpressionString);
      this.readableExpression = this.getReadableExpression(this.cronExpressionString);
    },
    validateWeeklyCronExpression () {
      this.haveErros = [];
      this.cronExpressionString = `${this.cronExpression.minutes ? this.cronExpression.minutes : '*'} ${this.cronExpression.hourly ? this.cronExpression.hourly : '*'} ? * ${this.cronExpression.weekly} *`;
      this.validateCron = this.validateCronExpression(this.cronExpressionString);
      this.readableExpression = this.getReadableExpression(this.cronExpressionString);
    },
    validateMonthlyCronExpression () {
      this.haveErros = [];
      this.cronExpressionString = `${this.cronExpression.minutes ? this.cronExpression.minutes : '*'} ${this.cronExpression.hourly ? this.cronExpression.hourly : '*'} ${this.cronExpression.daily ? this.cronExpression.daily : '*'} ${this.cronExpression.monthly} ? *`;
      this.validateCron = this.validateCronExpression(this.cronExpressionString);
      this.readableExpression = this.getReadableExpression(this.cronExpressionString);
    },
    toogleDayOfWeek (day) {
      this.daysOfWeek = this.daysOfWeek.map((dayOfWeek) => {
        if (dayOfWeek.day === day) {
          return {
            ...dayOfWeek,
            active: !dayOfWeek.active,
          };
        } else {
          return { ...dayOfWeek };
        }
      });
      this.cronExpression.weekly = this.daysOfWeek.filter((dayOfWeek) => dayOfWeek.active).map((dayOfWeek) => dayOfWeek.day).join(',');
      this.validateWeeklyCronExpression();
    },
    setActiveItem (event) {
      this.showForm = event.currentTarget.id;
      this.tabsContent.forEach((item) => {
        if (item.id === event.currentTarget.id) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    },
    async addCronExpression () {
      this.haveErros = [];
      if (this.isEnabledRetro && !this.validateRetroInDays(this.retroInDays)) {
        return;
      }
      this.isLoading = true;
      const hasExpressions = this.cronExpressions.every(({ cron }) => cron);
      if (!hasExpressions) {
        this.isLoading = false;
        return;
      };
      if (!this.isCronExpressionUnique(this.cronExpressionString)) {
        this.haveErros.push({
          title: this.$t('cronExpressions.finalExpression'),
          detail: this.$t('cronExpressions.isCronExpressionUnique'),
        });
        this.isLoading = false;
        return;
      }
      const newCronExpressionObj = {
        name: this.name && this.displayName,
        cron: this.cronExpressionString,
        cronExpressionReadable: this.readableExpression,
        parser: this.selectedParser.id,
        isActive: this.isActive,
        isEnabledRetro: this.isEnabledRetro || false,
        retroInDays: parseInt(this.retroInDays, 10) || 0,
      };
      const newCronExpression = cronExpressionFactory(newCronExpressionObj);
      delete newCronExpression.id;
      try {
        const { data: createdJobScheduled } = await postJobScheduler(newCronExpression);
        this.isLoading = false;
        if (createdJobScheduled.errors) {
          this.haveErros = createdJobScheduled.errors;
        } else {
          createdJobScheduled.cronExpressionReadable = this.readableExpression;
          this.cronExpressions = [...this.cronExpressions, createdJobScheduled];
          this.$emit('updateCronExpressions', this.cronExpressions);
          this.$emit('updateModalIsVisible', false);
        }
      } catch (e) {
        this.haveErros = e.data.errors;
        this.nameError = this.getErrorFromErrors(this.haveErros, 'name', 'Name');
        this.isLoading = false;
      }
    },
    async editCronExpression () {
      this.haveErros = [];
      if (this.isEnabledRetro && !this.validateRetroInDays(this.retroInDays)) {
        return;
      }
      if (!this.isCronExpressionUnique(this.cronExpressionString, this.selectedCronExpression.id)) {
        this.haveErros.push({
          title: this.$t('cronExpressions.finalExpression'),
          detail: this.$t('cronExpressions.isCronExpressionUnique'),
        });
        this.isLoading = false;
        return;
      }
      this.isLoading = true;
      const cronExpressionObj = {
        ...this.selectedCronExpression,
        name: this.name,
        cron: this.cronExpressionString,
        cronExpressionReadable: this.readableExpression,
        isActive: this.isActive,
        isEnabledRetro: this.isEnabledRetro || false,
        retroInDays: parseInt(this.retroInDays, 10) || 0,
      };
      const { data: updatedJobScheduled } = await putJobScheduler(cronExpressionObj);
      this.isLoading = false;
      updatedJobScheduled.cronExpressionReadable = this.readableExpression;
      this.cronExpressions = this.cronExpressions.map((cronExpression) => cronExpression.id === updatedJobScheduled.id ? updatedJobScheduled : cronExpression);
      this.$emit('updateCronExpressions', this.cronExpressions);
      this.$emit('updateModalIsVisible', false);
    },
    setDisplayName () {
      this.nameError = false;
      this.haveErros = [];
      this.displayName = `DAP_EM${this.envName}_${this.selectedParser.name}_${this.name.replace(/\s+/g, '_')}`;
    },
    getErrorFromErrors (errors, property, displayName) {
      const errorMsg = errors
        .filter((error) => error.detail.includes(`[Property]: ${property}`))
        .map((error) => {
          const match = error.detail.match(/\[Constraint Violation\]:\s*(.*?)\s*\[Property\]:/);
          return match ? match[1] : null;
        })[0];
      return errorMsg && `${displayName}: ${errorMsg}`;
    },
  },
  headers: EXPRESSIONS_HEADERS,
};
</script>

<style lang="scss" scoped>
@import '@mds/typography';

.cron-form {
  &__title {
    @include mds-body-text-m();
    display: flex;
    flex-wrap: wrap;
  }
  &__retro {
    @include mds-body-text-m();
    display: flex;
  }
  &__tooltip {
    width: 300px;
  }
  &__grid {
    margin-top: $mds-space-2-x;
  }
  &__fieldset {
    display: flex;
    flex-wrap: wrap;
    label {
      width: calc(25% - 20px);
      margin-right: $mds-space-2-and-a-half-x;
    }
  }
  &__fieldset_days {
    display: flex;
    flex-wrap: wrap;
    label {
      width: calc(10% - 20px);
      margin-right: $mds-space-2-and-a-half-x;
    }
  }
  &__display_name {
    span {
      font-weight: bold;
    }
    margin-top: $mds-space-three-quarter-x;
  }
}
.mds-tooltip__dap-crawler {
  display: table-row;
  max-width: 300px;
}
</style>
