<template>
  <div>
    <mds-tabs>
      <mds-tabs-item
        v-for="parser in parsers"
        :key="parser.id"
        :active="parser.name === selectedTab"
        @click.native="setActiveNavItemOnClick(parser.name)"
      >
        {{ parser.name }}
      </mds-tabs-item>
    </mds-tabs>
  </div>
</template>

<script>
import { MdsTabs, MdsTabsItem } from '@mds/tabs';

export default {
  name: 'Tab',
  components: {
    MdsTabs,
    MdsTabsItem,
  },
  props: {
    parsers: {
      type: Array,
      default: () => ({}),
    },
    selectedTab: {
      type: String,
      default: '',
    },
  },
  mounted () {
    const defaultParserTab = this.parsers[0]?.name;
    this.setActiveNavItemOnClick(defaultParserTab);
  },
  methods: {
    setActiveNavItemOnClick (id) {
      this.$emit('setActiveTab', id);
    },
  },
};
</script>
