<template>
  <mds-row>
    <store-provider
      v-bind="$options.storeInfo"
      :params="{ exchangeId: $route.params.id }"
      :is-new-em="!$route.params.id"
    >
      <template v-slot="{ state: parsers }">
        <h2 class="tabs-section__title">
          Parsers
        </h2>
        <Nav
          :parsers="parsers"
          :selected-tab="selectedTab"
          @setActiveTab="setActiveTab($event)"
        />
        <parser-content
          :parsers="parsers"
          :selected-tab="selectedTab"
        />
      </template>
    </store-provider>
  </mds-row>
</template>

<script>
import StoreProvider from '@/components/common/StoreProvider';
import Nav from '@/components/exchange-monitor/tabs/nav/Nav';
import ParserContent from '@/components/exchange-monitor/tabs/content/ParserContent';
import { COMMON, MODULE } from '@/constants/store.constant';

export default {
  name: 'TabsSection',
  components: {
    StoreProvider,
    Nav,
    ParserContent,
  },
  data () {
    return {
      selectedTab: '',
    };
  },
  methods: {
    setActiveTab (tab) {
      this.selectedTab = tab;
    },
  },
  storeInfo: {
    module: MODULE.PARSERS,
    action: COMMON.FETCH_DATA,
    getter: COMMON.GET_STATE,
  },
};
</script>

<style lang="scss">
@import '@mds/typography';

.tabs-section {
  &__title {
    @include mds-level-4-heading($bold: true);
    margin-top: 25px;
  }
}
</style>
