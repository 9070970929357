<template>
  <mds-row>
    <store-provider
      v-bind="$options.storeInfo"
      :params="{ exchangeId: $route.params.id }"
      :is-new-em="!$route.params.id"
    >
      <template v-slot="{ state: exchange }">
        <exchange-form
          :exchange="exchange('exchange')"
          :data-source="exchange('dataSource')"
          :document-group="exchange('documentGroup')"
          :is-new="!$route.params.id"
        />
        <TabsSection />
      </template>
    </store-provider>
  </mds-row>
</template>

<script>
import StoreProvider from '@/components/common/StoreProvider';
import ExchangeForm from '@/components/exchange-monitor/info/EMForm';
import TabsSection from '@/components/exchange-monitor/tabs/TabsSection.vue';
import { COMMON, MODULE } from '@/constants/store.constant';

export default {
  name: 'EMFormSection',
  components: {
    StoreProvider,
    ExchangeForm,
    TabsSection,
  },
  storeInfo: {
    module: MODULE.EXCHANGE_MONITOR,
    action: COMMON.FETCH_DATA,
    getter: COMMON.GET_STATE,
  },
};
</script>
