/**
 * Formats the cron expressions's object structure
 * @param {object} expression Expression object
 * @returns {object} Returns a expression object
 */

import { randomId } from './global.util';

export const cronExpressionFactory = (expression) => ({
  name: expression?.name || '',
  cron: expression?.cron || '',
  cronExpressionReadable: expression?.cronExpressionReadable || '',
  executionPriority: 'MEDIUM',
  isActive: expression?.isActive || false,
  parser: expression.parser,
  isEnabledRetro: expression?.isEnabledRetro ?? false,
  retroInDays: expression?.retroInDays ?? '',
  id: randomId(),
});
