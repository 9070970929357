import http from '@/plugins/http';
import ENDPOINTS from '@/constants/endpoints.constant';

/**
 * @param {object} payload Job Scheduler properties to be created
 * @returns {promise} Promise object containing the created Job Scheduler
 */
export const postJobScheduler = (payload) => http.post(`${ENDPOINTS.JOB_SHEDULER}`, payload);

/**
 * @param {object} payload Job Scheduler properties to be updated
 * @returns {promise} Promise object containing the updated Job Scheduler
 */
export const putJobScheduler = ({ id, ...payload }) => http.put(`${ENDPOINTS.JOB_SHEDULER}/${id}`, payload);

/**
 * @param {object} id Job Scheduler id to be deleted
 * @returns {promise} Promise object containing the deleted Job Scheduler
 */
export const deleteJobScheduler = (id) => http.delete(`${ENDPOINTS.JOB_SHEDULER}/${id}`);
