<template>
  <mds-form>
    <mds-fieldset class="em-form__fieldset">
      <mds-input
        v-model="exchange.externalProfileId"
        class="em-form__input"
        label="Profile Uuid"
        disabled
      />
      <mds-input
        v-model="exchange.name"
        class="em-form__input"
        label="Name"
        disabled
      />
      <mds-input
        v-model="exchange.documentGroup"
        class="em-form__input"
        label="Document Group"
        disabled
      />
      <mds-input
        v-model="exchange.comment"
        class="em-form__input"
        label="Comment"
        disabled
      />
      <mds-input
        v-model="exchange.emUrl"
        class="em-form__input"
        label="Exchange Monitor Url"
        disabled
      />
    </mds-fieldset>
  </mds-form>
</template>

<script>
import MdsForm from '@mds/form';
import MdsFieldset from '@mds/fieldset';
import MdsInput from '@mds/input';

export default {
  name: 'EMForm',
  components: {
    MdsForm,
    MdsFieldset,
    MdsInput,
  },
  props: {
    exchange: {
      type: Object,
      default: () => ({}),
    },
    dataSource: {
      type: Array,
      default: () => ({}),
    },
    documentGroup: {
      type: Array,
      default: () => ({}),
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      exchanges: [],
    };
  },
};
</script>

<style lang="scss">
.em-form {
  &__input {
    &--hidden {
      display: none;
    }
  }
  &__fieldset{
    display: flex;
    flex-wrap: wrap;
    label {
      width: calc(50% - 20px);
      margin-right: 20px;
    }
  }

  &__switch {
    margin: 10px 0;
    width: 100%;
  }
}
</style>
