
<template>
  <div class="parser-content">
    <div v-if="selectedParser">
      <h3 class="parser-content__title">
        {{ selectedParser && selectedParser.displayName }}
      </h3>
      <div class="parser-content__container">
        <mds-fieldset
          legend="Status"
        >
          <status-tag
            class="parser-content__status"
            :status="selectedParser.active ? 'ACTIVE' : 'INACTIVE'"
          />
        </mds-fieldset>
        <mds-input
          v-model="selectedParser.url"
          label="URL"
          disabled
        />
        <mds-input
          v-model="parserClassName"
          label="Class Name"
          disabled
        />
      </div>
      <mds-section
        bold
        border="tertiary"
        title="Jobs Schedulers"
      >
        <ParsersTable
          :jobs="jobs"
          :selected-parser="selectedParser"
        />
      </mds-section>
      <!-- btn  save jobs -->
    </div>
  </div>
</template>

<script>
import MdsInput from '@mds/input';
import MdsFieldset from '@mds/fieldset';
import MdsSection from '@mds/section';
import ParsersTable from '@/components/exchange-monitor/parsers/ParsersTable.vue';
import { getParserJobSchedulers } from '@/services/api/exchange-monitors.service.js';
import { MODULE, COMMON } from '@/constants/store.constant';
import cronstrue from 'cronstrue';
import { textFormatter } from '@/utils/global.util';
import StatusTag from '@/components/ui/StatusTag';

export default {
  name: 'ParserContent',
  components: {
    MdsInput,
    MdsSection,
    MdsFieldset,
    StatusTag,
    ParsersTable,
  },
  filters: {
    textFormatter,
  },
  props: {
    parsers: {
      type: Array,
      default: () => ({}),
    },
    selectedTab: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      jobs: [],
    };
  },
  computed: {
    selectedParser () {
      const parser = this.parsers.filter((item) => item.name === this.selectedTab);
      return parser[0];
    },

    parserClassName () {
      const className = this.selectedParser?.className.split('.');
      return className[className.length - 1];
    },
  },
  watch: {
    selectedTab: {
      handler () {
        this.jobScheduler(this.selectedParser?.id);
      },
      deep: true,
      immediate: true,
    },
  },
  beforeDestroy () {
    this.$store.commit(`${MODULE.PARSERS_JOB_SCHEDULERS}/${COMMON.RESET_STATE}`);
  },
  methods: {
    async jobScheduler (id) {
      this.jobs = [];
      const currentJobs = this.$store.getters[`${MODULE.PARSERS_JOB_SCHEDULERS}/${COMMON.GET_STATE}`](id);

      if (id && !currentJobs) {
        const { data } = await getParserJobSchedulers({ parserId: id });
        const jobs = data.map((item) => ({ ...item, cronExpressionReadable: cronstrue.toString(item.cron, { dayOfWeekStartIndexZero: false, verbose: true }) }));
        this.$store.commit(`${MODULE.PARSERS_JOB_SCHEDULERS}/${COMMON.UPDATE_STATE}`, { jobs, parserId: id });
        this.jobs = jobs;
      } else {
        this.jobs = currentJobs;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@mds/typography';

.parser-content {
  padding: 0 10px;

  &__title {
    @include mds-level-4-heading($bold: true);
    margin-top: 25px;
  }

  &__container {
    margin-bottom: 20px;

    label {
      width: calc(50% - 20px);
      margin-right: 20px;
      margin-bottom: 10px;
    }
  }

  &__status {
    margin-bottom: 10px;
  }
}
</style>
