<template>
  <mds-layout-grid>
    <form-section />
  </mds-layout-grid>
</template>

<script>
import FormSection from '@/components/exchange-monitor/EMFormSection';

export default {
  name: 'ProviderPageEM',
  components: {
    FormSection,
  },
};
</script>
