export const EXPRESSIONS_HEADERS = [
  {
    fieldName: 'name',
    text: 'Name',
    style: {
      width: '20%',
    },
  },
  {
    fieldName: 'cron',
    text: 'Scheduler Expression',
    style: {
      width: '10%',
    },
  },
  {
    fieldName: 'cronExpressionReadable',
    text: 'Scheduler Expression Readable',
    style: {
      width: '20%',
    },
  },
  {
    fieldName: 'isActive',
    text: 'Status',
    style: {
      width: '10%',
    },
  },
  {
    fieldName: 'isEnabledRetro',
    text: 'Retroactivity',
    style: {
      width: '10%',
    },
  },
  {
    fieldName: 'retroInDays',
    text: 'Retroactivity Days',
    style: {
      width: '10%',
    },
  },
  {
    fieldName: 'actions',
    text: 'Actions',
    style: {
      width: '10%',
    },
  }
];
